<template>
  <div class="browse-record">
    <div class="top-page">
      <div class="navigate">
        <van-icon
          name="arrow-left"
          class="left"
          color="#000"
          size="18"
          @click="routerBack"
        />
        <div class="title">游览足迹</div>
        <div class="right" @click="edit">{{ isEdit ? "完成" : "编辑" }}</div>
      </div>
      <div class="calendar-top" v-if="list.length > 0" @click="selectDate">
        <!-- <van-icon name="arrow-left" /> -->
        <span class="txt">{{ nowDate }}</span>
        <van-icon name="arrow" />
      </div>
    </div>
    <GoodsList ref="refList" :isEdit="isEdit" @checkAll="checkAll"></GoodsList>
    <div class="bottom-button" v-if="isEdit">
      <div class="text">
        已选
        <span class="color">{{ goodsCount }}</span>
        个商品
      </div>
      <div class="button-box">
        <van-checkbox
          v-model="checked"
          checked-color="#ee0a24"
          @click="allChange"
          >全选</van-checkbox
        >
        <div class="button" @click="delGoods">删除</div>
      </div>
    </div>
    <div class="blank" v-if="list.length <= 0">
      <div class="blank-box">
        <img
          src="@/assets/blank/collect-blank-images@2x.png"
          class="blank-img"
        />
        <div class="blank-text">暂无足迹</div>
        <div class="blank-button" @click="goToUrl">去逛逛吧</div>
      </div>
    </div>
    <van-calendar
      v-model="calendarShow"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import { getGoodsBrowse, delBrowse } from "@/api/member.js";
import GoodsList from "./moudles/goods-list/goods-list";
import { Toast, Dialog } from "vant";
export default {
  name: "browse-record",
  components: { GoodsList },
  data() {
    return {
      calendarShow: false,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2010, 0, 31),
      list: [],
      isEdit: false,
      checked: false,
      goodsCount: "0",
      goodsIds: [],
      isRequest: false,
      nowDate: "",
      nowDateCenter: "",
    };
  },
  mounted() {
    function addZero(val) {
      val = Number(val);
      return val < 10 ? "0" + val : val;
    }
    let year = new Date().getFullYear();
    let month = addZero(new Date().getMonth() + 1);
    let day = addZero(new Date().getDate());
    this.nowDate = `${year}年${month}月${day}`;
    this.nowDateCenter = `${year}-${month}-${day}`;
    // this.getGoodsBrowse();
  },
  methods: {
    allChange() {
      this.$refs.refList.all(this.checked);
    },
    delGoods() {
      Dialog.confirm({
        title: "提示",
        message: "您确认删除记录嘛？",
      })
        .then(() => {
          this.delBrowse();
        })
        .catch(() => {
          // on cancel
        });
    },
    checkAll(bool, list) {
      this.checked = bool;
      this.goodsIds = list;
      this.goodsCount = list.length;
    },
    edit() {
      this.isEdit = !this.isEdit;
    },
    goToUrl() {
      this.$router.push({ name: "home" });
    },
    routerBack() {
      this.$router.go(-1);
    },
    selectDate() {
      this.calendarShow = true;
    },
    onConfirm(date) {
      function addZero(val) {
        val = Number(val);
        return val < 10 ? "0" + val : val;
      }
      let year = date.getFullYear();
      let month = addZero(date.getMonth() + 1);
      let day = addZero(date.getDate());
      let time = `${year}-${month}-${day}`;
      this.nowDate = `${year}年${month}月${day}`;
      let head = document.querySelector(".top-page");
      if (this.nowDateCenter == time) {
        this.$refs.refList.getElement("今日", head.offsetHeight);
      } else {
        this.$refs.refList.getElement(time, head.offsetHeight);
      }
      this.calendarShow = false;
    },
    async delBrowse() {
      if (this.isRequest) return;
      this.isRequest = true;
      const res = await delBrowse({ browse_id: this.goodsIds });
      if (res.code === 1) {
        this.$refs.refList.getList(res.data);
        this.$refs.refList.delGoods();
        // this.getGoodsBrowse();
        this.checkAll(false, []);
        Toast.success(res.msg);
        this.isRequest = false;
        this.goodsIds=[];
      } else {
        Toast.fail(res.msg);
        this.isRequest = false;
      }
    },
    async getGoodsBrowse() {
      const res = await getGoodsBrowse();
      // res.data[0].goods[0].status = 0;
      if (res.code === 1) {
        this.list = res.data;
        let startArr = res.data[0].day.split("-");
        let endArr = res.data[res.data.length - 1].day.split("-");
        if (this.nowDateCenter === res.data[0].day) {
          res.data[0].day = "今日";
        }
        if (startArr[1] - 1 < 0) {
          startArr[1] = 0;
        } else {
          startArr[1] -= 1;
        }
        if (endArr[1] - 1 < 0) {
          endArr[1] = 0;
        } else {
          endArr[1] -= 1;
        }
        this.maxDate = new Date(startArr[0], startArr[1], startArr[2]);
        this.minDate = new Date(endArr[0], endArr[1], endArr[2]);
        this.$refs.refList.getList(res.data);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.top-page {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
}
.navigate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 57px;
  padding: 0 23px;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e5;

  .title {
    font-size: 21px;
  }
  .right {
    font-size: 18px;
  }
}
.calendar-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
  font-size: 18px;
  background-color: #fff;
  .txt {
    margin: 0 10px;
  }
}
.bottom-button {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  .text {
    padding: 17px 0 0 26px;
    color: #bbbbbb;
    font-size: 16px;
    .color {
      color: #e02020;
    }
  }
  .button-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 63px;
    padding: 0 13px 0 26px;
    .button {
      width: 116px;
      height: 45px;
      color: #ed301d;
      font-size: 18px;
      border: 1px solid #ed301d;
      border-radius: 45px;
      text-align: center;
      line-height: 45px;
    }
  }
}
.van-checkbox__icon .van-icon {
  width: 19px;
  height: 19px;
}
/deep/.van-checkbox__label {
  font-size: 16px;
}
.blank {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  .blank-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .blank-img {
      width: 233px;
      height: 155px;
    }
    .blank-text {
      color: #919090;
      font-size: 18px;
    }
    .blank-button {
      width: 207px;
      height: 52px;
      margin-top: 4px;
      color: #fff;
      font-size: 21px;
      background: linear-gradient(260deg, #ed301d 0%, #fe5876 100%);
      box-shadow: 0px 2px 4px rgba(199, 8, 8, 0.26);
      border-radius: 52px;
      text-align: center;
      line-height: 52px;
    }
  }
}
</style>
