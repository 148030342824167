<template>
  <div v-if="list.length > 0">
    <ul class="date-block">
      <li
        class="date-list"
        :id="item.day"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="date-label">
          <div
            class="checkbox"
            :class="{ 'checkbox-color': item.is_check === 1 }"
            v-if="isEdit"
            @click="checkClick(item, index)"
          >
            <van-icon
              name="success"
              class="checkbox-icon"
              v-if="item.is_check === 1"
            />
          </div>
          <span>{{ item.day }}</span>
        </div>
        <ul class="goods-block">
          <li
            class="goods-list"
            v-for="(goods, gInd) in item.goods"
            :key="gInd"
          >
            <div
              class="checkbox"
              :class="{ 'checkbox-color': goods.is_check === 1 }"
              v-if="isEdit"
              @click="checkChild(goods, gInd, index)"
            >
              <van-icon
                name="success"
                class="checkbox-icon"
                v-if="goods.is_check === 1"
              />
            </div>
            <div class="img-box" @click="goodsDetail(goods)">
              <div class="mask-black" v-if="goods.status === 0">已下架</div>
              <img
                v-show="false"
                src="@/assets/common/consumption-golabel.png"
                class="expense"
                v-if="goods.is_expense === 1"
              />
              <img :src="goods.cover_img" class="goods-img" />
            </div>
            <div class="goods-info" @click="goodsDetail(goods)">
              <div class="mask-white" v-if="goods.status === 0"></div>
              <div class="goods-name">{{ goods.good_name }}</div>
              <div
                class="purchase"
                v-if="goods.goods_type === 2 && goods.source === 1"
              >
                进货商品
              </div>
              <div class="price" v-if="goods.source === 2">200公益豆</div>
              <div class="price" v-else>
                <span class="price-tag" v-if="goods.source === 3">
                  新人价
                </span>
                <Price :price="goods.price" integerStyle="21" />
                <div
                  class="goods-heat"
                  v-if="goods.goods_type === 2 && goods.source === 1"
                >
                  <div class="division">|</div>
                  <div>{{ goods.sales }}人付款</div>
                </div>
              </div>
              <div
                class="goods-heat"
                v-if="goods.goods_type === 1 && goods.source !== 2"
              >
                <img
                  src="@/assets/common/icon-good-opinion.png"
                  class="stars"
                />
                <div class="score">{{ goods.score }}分</div>
                <div class="division">|</div>
                <div>{{ goods.sales }}人付款</div>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { Toast } from "vant";
import tools from "@/common/js/tools";
export default {
  props: ["isEdit"],
  data() {
    return {
      list: [],
      goodsIds: [],
    };
  },
  mounted() {},
  methods: {
    goodsDetail(goods) {
      if(goods.status===0){
        tools.error('当前商品已下架')
        return ;
      }
      let goodsType = 1;
      let goodsId = goods.goods_id;
      if (goods.source === 3 && goods.newcomer_id) {
        goodsId = goods.newcomer_id;
        goodsType = 5;
      } else if (goods.source === 2 && goods.benefit_id) {
        goodsType = 3;
        goodsId = goods.benefit_id;
      } else if (goods.source === 1 && goods.goods_type === 2) {
        goodsType = 2;
      }
      this.$router.push({
        name: "detail",
        query: {
          goodsId: goodsId,
          type: goodsType,
        },
      });
    },
    delGoods(){
      this.goodsIds=[];
    },
    checkClick(item, fatherInd) {
      if (item.is_check === 1) {
        this.list[fatherInd].is_check = 0;
      } else {
        this.list[fatherInd].is_check = 1;
      }
      this.list[fatherInd].goods.forEach((item) => {
        if (this.list[fatherInd].is_check === 0) {
          item.is_check = 0;
          let index = this.goodsIds.indexOf(item.id);
          this.goodsIds.splice(index, 1);
        } else {
          item.is_check = 1;
          this.goodsIds.push(item.id);
        }
      });
      this.isAll();
    },
    checkChild(goods, sonInd, fatherInd) {
      if (goods.is_check === 1) {
        this.list[fatherInd].goods[sonInd].is_check = 0;
        let index = this.goodsIds.indexOf(goods.id);
        this.goodsIds.splice(index, 1);
      } else {
        this.list[fatherInd].goods[sonInd].is_check = 1;
        this.goodsIds.push(goods.id);
      }
      let bool = this.list[fatherInd].goods.some((item) => item.is_check === 0);
      if (!bool) {
        this.list[fatherInd].is_check = 1;
      } else {
        this.list[fatherInd].is_check = 0;
      }
      this.isAll();
    },
    all(checked) {
      this.goodsIds = [];
      this.list.forEach((item) => {
        item.is_check = checked ? 1 : 0;
        item.goods.forEach((value) => {
          if (checked) {
            value.is_check = 1;
            this.goodsIds.push(value.id);
          } else {
            value.is_check = 0;
          }
        });
      });
      this.isAll();
    },
    isAll() {
      for (const item of this.list) {
        for (const value of item.goods) {
          if (value.is_check === 0) {
            this.$emit("checkAll", false, this.goodsIds);
            return false;
          }
        }
      }
      this.$emit("checkAll", true, this.goodsIds);
      return true;
    },
    getList(listArr) {
      this.list = listArr;
    },
    getElement(time, headHeight) {
      let arr = [];
      let element = document.querySelectorAll(".date-list");
      for (const item of element) {
        arr.push(item.id);
      }
      let index = arr.indexOf(time);
      if (index > -1) {
        for (let i = 0; i < element.length; i++) {
          if (element[i].id === time) {
            window.scrollTo(0, element[i].offsetTop - headHeight);
            return;
          }
        }
      } else {
        Toast.fail("没有记录");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ededed;
  margin-right: 13px;
  border-radius: 50%;
  .checkbox-icon {
    font-size: 16px;
    color: #fff;
  }
}
.checkbox-color {
  box-shadow: 0px 2px 4px rgba(237, 55, 36, 0.19);
  background: linear-gradient(129deg, #f0695b 0%, #ed301d 100%);
  border: 1px solid transparent;
}
.date-block {
  padding: 0 13px;
  margin-bottom: 110px;
  margin-top: 115px;
  .date-label {
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 18px;
    color: #999;
  }
}
.goods-block {
  background: #fff;
  border-radius: 13px;
  padding: 10px 12px;
  .goods-list {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &:last-child {
      margin: 0;
    }
    .img-box {
      flex-shrink: 0;
      position: relative;
      width: 123px;
      height: 123px;
      margin-right: 10px;
      .mask-black {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        border-radius: 6px;
      }
      .goods-img {
        width: 100%;
        height: 100%;
        background-color: rgb(240, 240, 240);
        border-radius: 6px;
      }
      .expense {
        position: absolute;
        top: 0;
        left: 0;
        width: 54px;
        height: 21px;
      }
    }
  }
  .goods-info {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px 0;
    height: 123px;
    .mask-white {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: 9;
    }
    .goods-name {
      font-size: 17px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
    }
    .price {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #ed301d;
    }
    .price-tag {
      color: #ed301d;
      font-size: 12px;
      padding-top: 2px;
    }
    .purchase {
      width: 58px;
      height: 19px;
      color: #ec301c;
      border: 1px solid #ec301c;
      border-radius: 3px;
      text-align: center;
      line-height: 19px;
      margin: 6px 0;
    }
  }
  .goods-heat {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #bbbbbb;
    .stars {
      width: 13px;
      height: 13px;
      margin-right: 6px;
    }
    .division {
      margin: 0 6px;
    }
  }
}
</style>
